<template>
  <div class="record">
    <!-- 头部工具栏 -->
    <div class="tool">
      <ul>
        <li>
          <img
            src="../../../../assets/images/refreshBtn.png"
            @click="refresh()"
            alt=""
          />
        </li>
        <li>
          <img
            src="../../../../assets/images/retreatBtn.png"
            @click="back()"
            alt=""
          />
        </li>
      </ul>
    </div>
    <div class="report-main">
      <div class="content" id="content_a">
        <!-- 标题 -->
        <div class="report-title">
          <p class="title">{{ config.unit_name }}</p>
          <p class="userName">
            <!-- thyo -->
            <span>心理档案表</span>
          </p>
        </div>
        <!-- 基本情况 -->
        <p class="headline">1、基本情况</p>
        <div class="content_basic">
          <table>
            <tr>
              <td>姓名</td>
              <td>{{ userInfo.real_name }}</td>

              <td>科室</td>
              <td>{{ userInfo.department_name }}</td>
              <td>性别</td>
              <td>{{ userInfo.sex == 0 ? "女" : "男" }}</td>
              <td>出生日期</td>
              <td>{{ userInfo.age }}</td>
            </tr>
          </table>
        </div>
        <!-- 家庭情况 -->
        <p class="headline">2、家庭情况</p>
        <div class="content_basic">
          <table>
            <tr>
              <th>称谓</th>
              <th>姓名</th>
              <th>年龄</th>
              <th>学历</th>
              <th>职业</th>
              <th>爱好与特长</th>
              <th>个性特点</th>
              <th>你对他(她)的喜爱程度</th>
            </tr>
            <tr v-for="(item, key) in form1" :key="key">
              <td>{{ item.appellation | appellation }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.age }}</td>
              <td>{{ item.education | filterseducation }}</td>
              <td>{{ item.professional_name }}</td>
              <!-- <td>
                {{
                  item.professional_name
                    ? professional_name
                    : item.professional | filtersoccupation
                }}
              </td> -->
              <td>
                <span>{{ item.hobbies }}</span>
              </td>
              <td>{{ item.personality }}</td>
              <td>{{ item.liking | filterslove }}</td>
            </tr>
          </table>
        </div>
        <!-- 学习经历 -->
        <p class="headline">2、学习经历</p>
        <div class="content_basic">
          <table>
            <tr>
              <th style="width: 200px">起止时间</th>
              <th style="width: 150px">在何校学习</th>
              <th>担任职务</th>
              <th style="width: 100px">对当时所处集体的喜爱程度</th>
            </tr>
            <template v-if="form2.length">
              <tr v-for="(item, key) in form2" :key="key">
                <td>
                  {{ item.start_time.split(" ")[0] }}至{{
                    item.end_time.split(" ")[0]
                  }}
                </td>
                <td>{{ item.school_name }}</td>
                <td>{{ item.position }}</td>
                <td>{{ item.liking | filterslove }}</td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="4">未填写</td>
            </tr>
          </table>
        </div>
        <!-- 成功与失败经历 -->
        <p class="headline">3、成功与失败经历</p>
        <div class="content_basic">
          <table>
            <tr>
              <th style="width: 200px">时间</th>
              <th>成功经历</th>
            </tr>
            <template v-if="success">
              <tr v-for="(item, key) in success" :key="key">
                <td>{{ item.date_time.split(" ")[0] }}</td>
                <td>{{ item.desc }}</td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="2">无</td>
            </tr>
          </table>
          <table>
            <tr>
              <th style="width: 200px">时间</th>
              <th>失败经历</th>
            </tr>
            <template v-if="failed">
              <tr v-for="(item, key) in failed" :key="key">
                <td>{{ item.date_time.split(" ")[0] }}</td>
                <td>{{ item.desc }}</td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="2">无</td>
            </tr>
          </table>
        </div>
        <!-- 自我评价 -->
        <p class="headline">4、自我评价</p>
        <div class="content_basic">
          <table>
            <tr>
              <th></th>
              <th>很好</th>
              <th>较好</th>
              <th>一般</th>
              <th>较差</th>
              <th>很差</th>
            </tr>
            <tr>
              <td>人际关系</td>
              <td>
                <span v-if="form4.interpersonal_relationships == 0">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.interpersonal_relationships == 1">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.interpersonal_relationships == 2">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.interpersonal_relationships == 3">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.interpersonal_relationships == 4">
                  <i class="el-icon-check"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>学习成绩</td>
              <td>
                <span v-if="form4.study_result == 0">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.study_result == 1">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.study_result == 2">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.study_result == 3">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.study_result == 4">
                  <i class="el-icon-check"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>学习态度</td>
              <td>
                <span v-if="form4.learning_attitude == 0">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.learning_attitude == 1">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.learning_attitude == 2">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.learning_attitude == 3">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.learning_attitude == 4">
                  <i class="el-icon-check"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>学习习惯</td>
              <td>
                <span v-if="form4.study_habits == 0">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.study_habits == 1">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.study_habits == 2">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.study_habits == 3">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.study_habits == 4">
                  <i class="el-icon-check"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>思想品德</td>
              <td>
                <span v-if="form4.ideological_moral == 0">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.ideological_moral == 1">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.ideological_moral == 2">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.ideological_moral == 3">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.ideological_moral == 4">
                  <i class="el-icon-check"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>行为习惯</td>
              <td>
                <span v-if="form4.behavior == 0">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.behavior == 1">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.behavior == 2">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.behavior == 3">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.behavior == 4">
                  <i class="el-icon-check"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>身体状况</td>
              <td>
                <span v-if="form4.physical_condition == 0">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.physical_condition == 1">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.physical_condition == 2">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.physical_condition == 3">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.physical_condition == 4">
                  <i class="el-icon-check"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>自控能力</td>
              <td>
                <span v-if="form4.self_control == 0">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.self_control == 1">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.self_control == 2">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.self_control == 3">
                  <i class="el-icon-check"></i>
                </span>
              </td>
              <td>
                <span v-if="form4.self_control == 4">
                  <i class="el-icon-check"></i>
                </span>
              </td>
            </tr>
          </table>
        </div>
        <!-- 困惑与烦恼 -->
        <p class="headline">5、困惑与烦恼</p>
        <div class="content_basic">
          <table>
            <tr>
              <th>困惑与烦恼</th>
            </tr>
            <template v-if="form5.length">
              <tr v-for="(item, key) in form5" :key="key">
                <td>{{ item.content }}</td>
              </tr>
            </template>
            <tr v-else>
              <td>无</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArchives, listArchives } from "@/api/information.js";
import { loadArchivesInfo, userInfo } from "@/api/evaluate.js";
import moment from "moment";
import { log } from "util";
export default {
  inject: ["reload"],
  data() {
    return {
      config: {},
      form1: [],
      form2: [],
      form3: [],
      form4: {
        interpersonal_relationships: -1,
        study_result: -1,
        learning_attitude: -1,
        study_habits: -1,
        ideological_moral: -1,
        behavior: -1,
        physical_condition: -1,
        self_control: -1,
      },
      form5: [],
      loveArr: ["喜爱", "较喜爱", "无所谓", "不喜爱"],
      seducation: [
        "文盲",
        "小学",
        "初中",
        "高中/中专/技校",
        "大学专科",
        "大学本科",
        "硕士",
        "博士及以上",
      ],
      appellationArr: [
        "父亲",
        "母亲",
        "继父",
        "继母",
        "养父",
        "养母",
        "爷爷",
        "奶奶",
        "姥爷",
        "姥姥",
        "哥哥",
        "姐姐",
        "弟弟",
        "妹妹",
      ],
      evaluateArr: ["很好", "较好", " 一般", "较差", "很差"],
      archivesData: [],
      occupation: [],
      uid: 0,
      failed: [],
      success: [],
      tableData: [],
      userInfo: {},
    };
  },
  created() {
    this.config = JSON.parse(localStorage.getItem("config"));
    this.uid = this.$route.query.id;
    this.getListArchives();
    this.getUserInfo();
    this.loadArchives();
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    // 加载自定义档案信息
    loadArchives() {
      loadArchivesInfo().then((res) => {
        this.archivesData = res.data;
        res.data.forEach((v) => {
          if (v.key == "hobby") {
            this.occupation = v.content;
          }
        });
        localStorage.setItem("ArchivesInfo", JSON.stringify(this.occupation));
      });
    },
    //获取档案 各种数据
    async getListArchives() {
      let data = {
        user_id: this.uid,
        page: 1,
        size: 15,
      };
      for (let i = 1; i <= 5; i++) {
        data.type = i;
        await listArchives(data).then((res) => {
          if (i === 3) {
            this.failed = res.data.data?.failed;
            this.success = res.data.data?.success;
            console.log(res.data, " res.data");
          } else {
            if (i === 1) {
              this.form1 = res.data.data;
            } else if (i === 2) {
              this.form2 = res.data.data;
            } else if (i === 4) {
              //console.log("res", res);
              if (res.data.data.length <= 0) {
                return;
              }
              this.form4 = res.data.data[0];
            } else if (i === 5) {
              this.form5 = res.data.data;
            }
          }
        });
      }
      //console.log(this.tableData);
    },
    // 获取个人信息
    getUserInfo() {
      userInfo({ id: this.uid }).then((res) => {
        this.userInfo = res.data.user.data;
      });
    },
  },
  filters: {
    // 职业
    filtersoccupation(val) {
      localStorage.getItem("ArchivesInfo");
      let arr = JSON.parse(localStorage.getItem("ArchivesInfo"));
      return arr[val];
    },
    // 学历
    filterseducation(val) {
      let arr = [
        "文盲",
        "小学",
        "初中",
        "高中/中专/技校",
        "大学专科",
        "大学本科",
        "硕士",
        "博士及以上",
      ];
      return arr[val - 1];
    },
    // 喜爱
    filterslove(val) {
      let arr = ["喜爱", "较喜爱", "无所谓", "不喜爱"];
      return arr[val - 1];
    },
    filtersevaluate(val) {
      let arr = ["很好", "较好", " 一般", "较差", "很差"];
      return arr[val];
    },
    appellation(val) {
      let appellationArr = [
        "父亲",
        "母亲",
        "继父",
        "继母",
        "养父",
        "养母",
        "爷爷",
        "奶奶",
        "姥爷",
        "姥姥",
        "哥哥",
        "姐姐",
        "弟弟",
        "妹妹",
      ];
      return appellationArr[val - 1];
    },
  },
};
</script>


<style lang="less">
.record {
  // 报告
  margin-top: 20px;
  position: relative;
  .tool {
    position: absolute;
    top: -68px;
    right: 0;
    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;
        img {
          width: 100px;
        }
      }
    }
  }
  .report-main {
    width: 100%;
    // 报告内容部分
    .content {
      width: 1000px;
      margin: auto;
      background: white;
      padding: 10px 60px 0 60px;
      .headline {
        font-size: 16px;
        font-weight: 700;
        margin-top: 25px;
      }
      // 报表头部
      .report-title {
        margin: 10px 0;
        .title {
          font-size: 30px;
          text-align: center;
          font-weight: 700;
          color: rgb(237, 133, 0);
        }
        .userName {
          padding: 10px 0;
          font-size: 20px;
          text-align: center;
          font-weight: 700;
          color: black;
        }
      }
      .content_basic {
        table {
          margin: 5px auto;
          width: 900px;
          border-top: 2px black solid;
          border-left: 2px black solid;
          border-spacing: 0;
          tr {
            width: 100%;
            font-size: 16px;
            th {
              padding: 5px;
              border-bottom: 2px black solid;
              border-right: 2px black solid;
              text-align: center;
              font-weight: 300;
            }
            td {
              min-width: 50px;
              padding: 5px;
              border-bottom: 2px black solid;
              border-right: 2px black solid;
              text-align: center;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}
</style>